.chatContainer{
    padding: 0 var(--page-padding);
}

.sendMsgContainer{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    left: 0;
    right: 0;
    box-shadow: -6px 0 8px #5a5959;
}

.fields{
    position: relative;
    width: 100%;
}

.fieldInput{
    width: 100%;
    height: 50px;
    padding: 0 60px;
}

.fieldButton{
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 0;
    height: 50px;
    border: 0;
    padding: 0 10px;
    background-color: var(--theme-primary);
    color: white;
}

.chatHistory{
    padding: 100px 0 !important;

}

.ago{
    font-size: 10px;
    opacity: 0.7;
}

.fixedHeader{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1;
}