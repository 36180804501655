.container {
  position: relative;

  .bell {
    width: auto;
    height: 25px;
  }
}

.dot {
  position: absolute;
  top: -.2rem;
  right: -.2rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 100%;
  background-color: #FF4A4A;
  border: .2rem solid var(--bg-color-muted)
}

.dotEnv{
  position: absolute;
  top: -.2rem;
  right: -.2rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 100%;
  background-color: #FF4A4A;
  border: .2rem solid var(--bg-color-muted);
  color: #FFFFFF;
  font-size: 1rem;
  text-align: center;
}