.progress {
  width: 100%;
  border-radius: 10px;
  background-color: var(--foreground);
  overflow: hidden;

  display: flex;
  
  .bar {
    transition: width .5s ease-in-out;
    height: 100%;
    background-color: var(--theme-primary);
  }
}

.healthy {
  background-color: var(--theme-primary) !important;
}

.warning {
  background-color: var(--state-warning) !important;
}

.incomplete {
  background-color: var(--state-incomplete) !important;
}

.normal {
  height: 6px;
}

.small {
  height: 3px;
}