.loading {
  background-color: var(--border-color-light);
  border-radius: 100%;
}

.bubble {
  background-color: var(--border-color-dark);
  height: 100%;
  width: 100%;
  animation-name: bubbling;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 100%;
}

@keyframes bubbling {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.small {
  width: 25px;
  height: 25px;
}

.normal {
  width: 50px;
  height: 50px;
}

.large {
  width: 85px;
  height: 85px;
}