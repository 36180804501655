.container {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  padding-top: .5rem;
  pointer-events: none; 
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 1rem;
  min-width: 200px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid lightgrey;
  height: 40px;
  border-radius: 2rem;
  animation-name: fadeInOut;
  animation-duration: 2.5s;
}

@keyframes fadeInOut {
  0% {
    // opacity: 0;
    transform: translateY(-110%);
  }

  20%, 80% {
    // opacity: 1;
    transform: translateY(0);
  }

  100% {
    // opacity: 0;
    transform: translateY(-110%);
  }
}