.item {
  display: flex;
  gap: 1.2rem;

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4.5rem;
      height: 4.5rem;
      border: .2rem solid white;
      border-radius: 100%;
    }
  }

  .content {
    
    .name {
      font-size: 1.4rem;
    }

    .total {
      font-size: 1.8rem;
    }

    .stats {
      display: flex;
      gap: .5rem;
      font-size: 1.4rem;

      .minor {
        color: var(--color-minor);
      }
      
      .major {
        color: var(--color-major);
      }

      .severe {
        color: var(--color-severe);
      }

      .crisis {
        color: var(--color-crisis);
      }
    }
  }
}