.detail {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  height: 5rem;
  padding: 0 var(--page-padding);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.2rem;
    width: 2.2rem;

    .image {
      width: 100%;
      height: auto;
    }

    .placeholder {
      background-color: rgba(72, 72, 72, .2);
      height: .5rem;
      width: .5rem;
      border-radius: 10rem;
    }
  }

  .text {
    font-size: 1.6rem;
    color: rgba(72, 72, 72, 1);
  }
}