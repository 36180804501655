.doc {
  display: flex;
  gap: 1.2rem;
  align-items: center;

  .icon {
    max-height: var(--icon-size);
    max-width: var(--icon-size);
    width: 100%;
  }
}