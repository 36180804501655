.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--page-padding);
  width: 100%;
  height: 64px;
}

.menu {
  width: auto;
  height: 25px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkgrey;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  color: white;
  font-weight: 400;
  font-size: 1.8rem;
  overflow: hidden;

  .image {
    height: 100%;
  }
}

.tabHeader {
  display: none;
  @media screen and (min-width: 641px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--page-padding);
    width: 100%;
    height: 90px;
  }
}

.searchContainerWidth{
  
}

.allMenu{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.tabMenu{
  ul{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    li{
      list-style: none;
      padding: 0 var(--page-padding);
      
      a {
        font-family: Roboto Slab;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.408px;
        color: #898989;
      }
    }
  }
}