.container {
  min-width: 100%;

  .chart {
    max-height: 100px;
  }

  .columns {
    display: flex;
    justify-content: space-between;
    margin: .3rem .1rem;

    > div {
      height: 1rem;
      width: .2rem;
      background-color: lightgray;
    }
  }

  .dates {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
  }
}