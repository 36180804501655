.header {
  padding: 16px 0 8px 0;
  font-size: 18px;
  font-weight: 900;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeImage {
  width: 20px;
  height: 20px;
}