.bigsection{
    width: 69%;
    background-color: var(--bg-color);
    border-radius: var(--dashboard-card-border-radius) var(--dashboard-card-border-radius) 0 0;
    box-shadow: -1px -3px 8px #d2cfcf;
}

.smallsection{
    width: 30%;
    background-color: var(--bg-color);
    border-radius: var(--dashboard-card-border-radius) var(--dashboard-card-border-radius) 0 0;
    box-shadow: -1px -3px 8px #d2cfcf;
    min-height: 100vh;
}