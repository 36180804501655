.menu {
  position: fixed;
  overflow: scroll;
  top: 0;
  right: 0;
  height: 100%;
  width: 318px;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  background-color: white;
  transition: all .2s ease-out;
  transform: translateX(100%);
  box-shadow: 4px 0px 16px 4px rgba(0, 0, 0, 0.05);

  .section {
    padding: 0 1.6rem;
  }
}

.close {
  height: 20px;
  width: 20px;
  margin: 14px;
}

.open {
  transform: translateX(0%);
}


