.container {
  display: flex;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  margin: -1px 0;
  min-height: 100px;

  --bar-width: 1.2rem;

  .bar {
    min-height: 100%;
    min-width: var(--bar-width);
  }

  .task {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 100%;
    width: 100%;
    padding: 10px var(--page-padding) 10px calc(var(--page-padding) - var(--bar-width));
  
    .title {
      font-size: 1.8rem;
    }
  
    .subtitle {
      font-size: 1.4rem;
      color: rgba(137, 137, 137, 1);
    }
  }
}

.minor {
  background-color: var(--color-minor);
}

.major {
  background-color: var(--color-major);
}

.severe {
  background-color: var(--color-severe);
}

.crisis {
  background-color: var(--color-crisis);
}

.none {
  background-color: transparent;
}