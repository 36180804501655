// CSS variables
:root {
  --font-default: "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-heading: "Roboto Slab";
  --font-color: #000000;
  --font-color-muted: #484848;
  --font-color-light: #B8B8B8;
  --font-color-link: #0066cb;
  --font-color-link-visited: #006bff;
  --font-size: 15px;
  --focus-color: #2684ff;
  --focus-background: #b9d7ff;
  --page-padding: 16px;

  // Icons
  --icon-size: 2.4rem;

  // Main colors
  --color-grey: #898989;
  --color-red: #FF4A4A;

  // Assessment states
  --state-warning: #FF4A4A;
  --state-incomplete: #898989;

  // Foreground 
  --foreground: #DDDDDD;

  // Background
  --bg-color: #ffffff;
  --bg-color-muted: #f0f0f0;

  // Shadow
  --box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);

  // Cards
  --card-padding: 25px;
  --card-border-radius: 8px;
  --card-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);

  // Dashboard Card
  --dashboard-card-border-radius: 5rem;

  // Border colors
  --border-color-dark: #D2DDEC;
  --border-color-light: #898989;
  --border-color: #DDDDDD;

  // Input
  --input-border-color: #898989;
  --input-border-radius: 6px;
  --input-height: 4rem;
  --input-font-size: 1.8rem;

  // LabeledText
  --label-weight: 400;
  --text-weight: 700;

  // Theme 
  --theme-primary: #186A69;
  --theme-light: #FFFFFF;

  // Button
  --button-border-color: #e3ebf6;
  --button-text-color-light: #283e59;

  // Severity
  --color-minor: #71ADBB;
  --color-major: #E0B259;
  --color-severe: #d145ca;
  --color-crisis: #FF4A4A;
}