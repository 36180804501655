.workflows {
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 16px 0;
}

.workflow {
  display: flex;
  align-items: center;
  
  padding: 0 28px;
  height: 68px;
  border-radius: 18px;

  color: #FFFFFF;
  background-color: #E0B259;
}

.warning{
  color: var(--state-warning);
}