.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: white;
  transition: all .2s ease-out;
  transform: translateX(-100%);
  padding: 16px;
  box-shadow: 4px 0px 16px 4px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  overflow-x: hidden;
}


.top {
  top: 10px;
  display: flex;
  gap: 16px;  
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}

.close {
  height: 20px;
  width: 20px;
}

.open {
  transform: translateX(0%);
}


