.container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;

  .toggle {
    position: relative;
    box-sizing: content-box;
    user-select: none;
    background-color: white;
    border-radius: 50px;
    border: 1px solid var(--input-border-color);
    width: 80px;
    height: var(--input-height);
    transition: background-color .25s ease-out;
    
    .button {
      box-sizing: border-box;
      border: 5px solid white;
      position: absolute;
      width: var(--input-height);
      height: var(--input-height);
      border-radius: 50px;
      background-color: var(--border-color);
      transition: all .25s ease-out;
      left: 0px;
    }
  }

  .children {
    font-size: var(--input-font-size);
    color: var(--font-color);
  }
}

.label {
    color: var(--font-color);
    font-size: var(--input-font-size);
}

.error {
  font-size: 10px;
  font-weight: 400;
  margin-left: 5px;
  color: #ff746d;
}

.toggled {
  left: 100% !important;
  transform: translateX(-100%);
  background-color: var(--theme-primary) !important;
}

.wide {
  width: 100%;
  justify-content: space-between;
}

.reverse {
  flex-flow: row-reverse;
}

.disabled {
  background-color: #f2f2f2 !important;
}

.disabledButton {
  border-color: #f2f2f2 !important;
}