.button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  padding-left: 14px;
  cursor: pointer;

  font-family: 'Roboto Slab';
  font-size: 25px;
  letter-spacing: -0.408px;
}