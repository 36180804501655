.autocomplete {
    position: relative;
}
  
  .autocompleteDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1;
    border-radius: 5px;
  }
  
  .autocompleteSearchResultsList {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #f5f5f5;
  }

  .autocompleteSearchResultsCategory{
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #e0e0e0;
  }

  .catSpan{
    padding: 0.75rem 1rem;
  }
  
  .autocompleteSearchResult {
    padding: 0.75rem 1rem;
    cursor: pointer;
    border-bottom: #eaeaea 1px solid;
  }

  .autocompleteSearchResult:last-child{
    border-bottom: none;
  }
  
  .autocompleteSearchResult:hover,
  .autocompleteSearchResult:focus,
  .autocompleteSearchResult:active {
    background-color: #f9fafc;
  }
  
  .rButton{
    border: none;
    background-color: transparent;
    text-align: inherit;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .idate{
    color: #555555;
    font-size: 12px;
  }