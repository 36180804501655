.label {
  display: flex;
  align-items: center;
  gap: .5rem;

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100%;
  }
}