.tab {
 width: fit-content;
 padding: 1rem 1rem 0 1rem;

 .bar {
   height: .3rem;
   width: 100%;
   background-color: transparent;
   margin-bottom: 1rem;
 }
}

.active {
  color: rgba(113, 173, 187, 1);

  .bar {
    background-color: rgba(113, 173, 187, 1);
  }
}
