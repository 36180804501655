.base {
  background-color: white;
  width: 100%;
  min-height: 65rem;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.25);
  border-radius: var(--dashboard-card-border-radius);
  padding-bottom: 8rem;
  max-width: 1024px;
}

.draggable {
  width: 100%;
  position: absolute;
  top: 0rem;
}