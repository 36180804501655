.card {
  height: 35rem;
  width: 100%;
  border-radius: var(--dashboard-card-border-radius);
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 4rem;
  background: linear-gradient(0deg, #70ACBA 32.81%, rgba(112, 172, 186, 0.47) 61.85%);
  height: 100%;
  width: 100%;
}