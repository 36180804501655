.icon {
  image-rendering: optimizeQuality;
  object-fit: cover;
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
}
.ListIcon {
  image-rendering: optimizeQuality;
  object-fit: cover;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin-right: 1rem;
}

.title {
  font-size: 1.4rem;
  color: rgba(137, 137, 137, 1);
  margin-bottom: 1rem;
}

.actionButton {
  margin: 1rem;
  float: right;
}

.ago{
  font-size: 10px;
  opacity: 0.7;
}

.msgList{
  background-color: #ffffff;
  color: rgb(29, 29, 29);
}
