.initialPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .35 );
  
    .initialPopup {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      background-color: white;
      padding: 1rem 2rem;
      border-radius: 2rem;
      width: 30rem;
    }
  }