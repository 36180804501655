.container {
  display: flex;
  flex-flow: column;
  gap: 5px;
  min-width: 200px;

  .label {
    color: var(--font-color);
    font-size: var(--input-font-size);
  }

  .sublabel {
    color: var(--font-color-muted);
    font-size: 13px;
    font-weight: 400;
  }

  .error {
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
    color: #ff746d;
  }

  .input {
    color: #363636;
    background-color: #FFFFFF;
    min-height: 40px;
    height: fit-content;
    outline: 0;
    padding: 10px 12px;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--border-color-light);
    font-size: 15px;
    resize: vertical;
    overflow-y: hidden;
    
    &:focus {
      border: solid 1px #2684ff;
    }

    &::placeholder {
      color: #d4d4d4;
    }
  }
}