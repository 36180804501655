.avaterContainer{
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkgrey;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  color: white;
  font-weight: 400;
  font-size: 1.8rem;
  overflow: hidden;

  .image {
    height: 100%;
  }
}


.cover {
  height: 250px;
  border-radius: var(--dashboard-card-border-radius) var(--dashboard-card-border-radius) 0 0;
  background-position: center;
  background-size: cover;

  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem;
    width: 100%;
    height: 100%;
    border-radius: var(--dashboard-card-border-radius) var(--dashboard-card-border-radius) 0 0;
    background: linear-gradient(0deg, #70ACBA 5.81%, rgba(112, 172, 186, 0.47) 61.85%);

    font-family: 'Roboto Slab';
    font-size: 4rem;
    color: white;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .detailsIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 100%;
        background-color: white;
      }

      .closeIcon{
        display: block;
        @media screen and (min-width: 641px) {
          display: none;
        }
      }
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-top: 20px;
  
  .percentage {
    position: absolute;
    left: 11%;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 4.5rem;
    height: 4.5rem;
    font-family: 'Roboto Slab';
    font-size: 2rem;
    border-radius: 100%;
  }

  .label {
    position: absolute;
    left: 3.1rem;
    top: 4.5rem;
    transform: translate(-50%, -50%);
    width: 4rem;
    height: 4.5rem;
    font-family: 'Roboto Slab';
    font-size: 2rem;
    text-align: center;
  }

  .description {
    
    margin-left: 2rem;
    width: 90%;
    font-family: 'Roboto Slab';
    overflow-y: auto;
  }

}

.alerts {
  padding: 1.2rem 0;
  background-color: #F1F7F8;
}

.alertItem {
  display: flex;
  gap: 3.5rem;
  padding: 2.5rem var(--page-padding);

  .icon {
    margin-top: 0.6rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1.4rem;
    }
  }
}

.resChangeFlex{
  .barchart{
    width: 100%;
  }
  
  @media screen and (min-width: 641px) {
    .barchart{
      width: 50%;
      float: left;
    }
  }

  &:after{
    clear: both; display:block; content:""; position:relative;
  }
}