.page {
  min-height: 100vh;
  height: fit-content;
  width: 100%;
}

.noscroll {
  position: fixed;
  overflow: hidden;
  max-height: 100%;
}

.dark {
  background-color: #F0F0F0;
}

.section {
  padding: 0 var(--page-padding);
}

.top {
  background-color: var(--bg-color-muted);
  padding-bottom: 26px;

  .title {
    display: flex;
    gap: 5px;
    margin-top: 20px;
    padding: 0 var(--page-padding);
  }
}

.divider {
  height: 1px;
  background-color: #DDDDDD;
}