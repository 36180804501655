.fields {
  display: flex;
  flex-flow: column;
  gap: 2rem;
  padding: 1rem 0;
}

input[type="file"] {
  display: none;
}

.imgwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px;
  border-radius: 50%;
  border-color: black;
}

.img {
  height: 100%;
  width: auto;
}

.imgupload{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: inherit;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: inherit;
  border: 1px;
  border-color: black;
}
.imgupload:hover:before{
 opacity: 1;
}