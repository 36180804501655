.metadata {
  min-height: 40px;
  margin: 1rem 0;

  .title {
    font-size: 1.4rem;
    color: rgba(137, 137, 137, 1);
    margin-bottom: 1rem;
  }

  .cal{
    font-size: 2.4rem;
  }

  .over{
    .title {
      color: var(--color-red);
    }

    div {
      color:var(--color-red);
    }
  }
}

.steps {
  padding-bottom: 2rem;
}

