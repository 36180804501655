.page {
  display: flex;
  flex-flow: column;
  min-height: 100%;
  height: 100%;
  padding: 0 var(--page-padding);
}

.bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.initialPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, .35 );

  .initialPopup {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 2rem;
    height: 56rem;
    width: 30rem;
  }
}