.number {
      font-size: 4rem;
    }

.item {
  display: flex;
  border-top: 1px solid var(--button-border-color);
  min-height: 11.6rem;

  .bar {
    margin: -1px 0;
    width: 1.2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    padding: 1.6rem;

    .category {
      display: flex;
      font-size: 1.4rem;
      gap: .4rem;

      .muted {
        color: darkgrey;
      }
    }

    .title {
      font-size: 2rem;
    }

    .number {
      font-size: 4rem;
      display: inline-block;
    }

    .description {
      font-size: 1.5rem;
      display: inline-block;
    }

    .bottom {
      display: flex;
      justify-content: space-between; 
      text-align:right;

      .date {
        font-size: 1.2rem;
        display: flex;
        align-items: right;
        text-align: right;
      }
    }
  }
}

.item:last-of-type {
  border-bottom: 1px solid var(--button-border-color);
}

// Severities
.crisis {
  background-color: var(--color-crisis);
}

.severe {
  background-color: var(--color-severe);
}

.major {
  background-color: var(--color-major);
}

.minor {
  background-color: var(--color-minor);
}

.none {
  background-color: transparent;
}

// NumberSeverities
.numberCritical {
  color: var(--color-crisis);
}

.numberSevere {
  color: var(--color-severe);
}

.numberModerate {
  color: var(--color-major);
}

.numberLow {
  color: var(--color-minor);
}

.numberNone {
  background-color: transparent;
}