.property{
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: var(--font-color-muted);
}

.heading{
    font-size: 1.8rem;
}

.container{
    padding: var(--page-padding);
    border-top: 1px solid var(--foreground);
}

.progression {
    border-top: 1px solid var(--font-color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    div {
        font-size: 1.4rem;
    }
}

.fadeText{
    color: var(--font-color-muted);
}