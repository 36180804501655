.incidentdetails {
  min-height: 100vh;
  overflow-y: auto;
  position: absolute;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 18px;
  font-weight: 900;
}

.container {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  gap: 10px;

  .image {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: var(--font-color-light);
  }

  .minor {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--color-minor);
  }

  .major {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--color-major);
  }

  .emergency {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--color-emergency);
  }
}

.img {
  padding: 10px;
  margin-right: 10px;
  height: 40px;
}

.cuttext { 
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.overflow{ 
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
}

.title {
  font-size: 1.4rem;
  color: rgba(137, 137, 137, 1);
  margin-bottom: 1rem;
}

.actionButton {
  margin: 1rem;
  float: right;
}

.ago{
  font-size: 10px;
  opacity: 0.7;
}

.msgList{
  background-color: #ffffff;
  color: rgb(29, 29, 29);
}

.cal{
  font-size: 2.4rem;
}

.over{
  .title {
    color: var(--color-red);
  }

  div {
    color:var(--color-red);
  }
}